import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/tmp/node_modules/@nib/gatsby-theme-mesh-docs/src/templates/Content.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PrimaryButton = makeShortcode("PrimaryButton");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <img src="/3925edc16c4ac38eacc0ba59aaed6af0/playroom-logo.png" width="164" alt="Playroom logo" />
    <p>{`Playroom is an incredibly powerful design tool created by the team at `}<a parentName="p" {...{
        "href": "https://github.com/seek-oss/playroom"
      }}>{`seek-oss`}</a>{`.`}</p>
    <p>{`Playroom allows you to quickly experiment with real Mesh components, real interactivity and real limitations of both the system and the web platform. By prototyping in the final medium you will be building within the capabilities of the system and not unknowingly straying from what's possible like you might in a traditional design tool.`}</p>
    <img src="/8a7a83fb6877d3ada13cca02d359a22a/playroom-demo.gif" alt="Playroom demo" />
    <p>{`Playroom's primary interface is a `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/introducing-jsx.html"
      }}>{`JSX`}</a>{` editor which will be very familiar for developers and offers "Snippets" to drop in components like you would Figma components, which may be more approachable for designers.`}</p>
    <p>{`Share your current state at any point by simply sharing the URL. Share with fellow designers for assistance and divergent iteration, share with your team for feedback and share with developers for streamlined developer handoff.`}</p>
    <PrimaryButton component="a" href="/playroom" mdxType="PrimaryButton">
  Open playroom
    </PrimaryButton>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      